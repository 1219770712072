<template>
  <admin>
    <page-header>
      <template v-slot:title><span v-text="trans(pageTitle)"></span></template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <v-card class="mb-5">
          <v-card-title>Change Password</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Current Password"
                    class="dt-text-field"
                    outlined
                    v-model="currentPassword"
                    :append-icon="isShowCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isShowCurrent ? 'text' : 'password'"
                    :rules="currentPasswordRules"
                    @click:append="isShowCurrent = !isShowCurrent"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password"
                    class="dt-text-field"
                    outlined
                    v-model="password"
                    :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isShowPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    @click:append="isShowPassword = !isShowPassword"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Confirm Password"
                    class="dt-text-field"
                    outlined
                    v-model="confirmPassword"
                    :rules="confirmPasswordRules"
                    @click:append="isShowConfirm = !isShowConfirm"
                    :append-icon="isShowConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isShowConfirm ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="d-flex justify-end my-6" flat tile>
                <v-btn
                  large
                  exact
                  color="green darken-1"
                  class="ma-1 white--text"
                  :loading="loadingSave"
                  :disabled="loadingSave"
                  @click="saveOnly"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  Save
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </admin>
</template>

<script>
import apiAuthService from "@/services/api/modules/authService";
import { mapActions } from "vuex";
import {
  hondaPasswordRequirements,
  confirmPassword,
  minLength,
} from "@/config/validations";

export default {
  data() {
    return {
      pageTitle: "Profile",
      valid: false,
      form: {},
      password: "",
      confirmPassword: "",
      currentPassword: "",
      isShowCurrent: false,
      isShowPassword: false,
      isShowConfirm: false,
      loadingSave: false,
      passwordRules: [
        (value) => !!value || "Please type password.",
        (value) => minLength(value, 10),
        hondaPasswordRequirements,
      ],
      confirmPasswordRules: [
        (value) => !!value || "Please type confirm password",
        (value) => confirmPassword(value, this.password),
      ],
      currentPasswordRules: [
        (value) => !!value || "Please type current password",
      ],
    };
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/show",
      authLogout: "auth/logout",
    }),
    async myProfile() {
      await apiAuthService
        .myProfile()
        .then((response) => {
          const user = response.data;

          this.form = {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
          };
        })
        .catch((error) => {
          const message = error.response.message;
          this.showSnackbar({
            text: message,
            timeout: 10000,
          });
        });
    },
    async saveOnly() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loadingSave = true;

      let self = this;

      await apiAuthService
        .updatePassword({
          email: this.form.email,
          password: this.password,
          current_password: this.currentPassword,
          password_confirmation: this.confirmPassword,
        })
        .then((response) => {
          if (response.data.success) {
            this.showSnackbar({
              text: response.data.message,
              timeout: 10000,
            });

            setTimeout(function () {
              self.authLogout();

              self.$router.push({ name: "auth.login" });
            }, 1500);
          } else {
            const message = response?.data.errors
              ? Object.values(response.data.errors).join(" ")
              : response.data.message;

            this.showSnackbar({
              text: message,
              timeout: 10000,
            });
          }
        })
        .catch((error) => {
          const message = error.response?.data.errors
            ? Object.values(error.response.data.errors).join(" ")
            : error.response.data.message;

          this.showSnackbar({
            text: message,
            timeout: 10000,
          });
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
  },
  mounted() {
    this.myProfile();
  },
};
</script>
  